.programBanner {
    background: url('../../Assets/programbanner.avif') center;
    background-size: cover;
}

.textstroke {
    /* Width and color values */
    -webkit-text-stroke: 2px navy;
}

.offerRibbon {
    clip-path: polygon(0% 0%, 74% 0%, 85% 50%, 74% 100%, 0% 100%);
    text-shadow: 2px 2px 5px #e4641b;
}