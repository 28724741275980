.Landingdiv {
    background: url("../../Assets/landingbaner.avif");
    background-size: cover;
    background-position: 100% 100%;
}

/* .landingStatistics {
    background: url("../../Assets/stats.avif");
    background-position: center;
    filter: grayscale(75%);
    background-size: cover;
    background-attachment: fixed;
} */

.empoweringCommunity {
    background: url("../../Assets/consolidated.avif");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.WelcomeCharity {
    background: url("../../Assets/about.webp");
    background-position: center;
    background-size: cover;
}