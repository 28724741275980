@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./FontStyles.css";

@media screen and (max-width: 300px) {
    .main-content {
        display: none;
    }
}

@media screen and (min-width: 301px) {
    .ShowMessage {
        display: none;
    }
}