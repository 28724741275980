@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

/* ======================================================================================================= */
@font-face {
  font-family: "kalam";
  src: url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap") format("truetype");
}

/* Rubik Font  */
@font-face {
  font-family: "Rubik";
  src: url("./Fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RubikBold";
  src: url("./Fonts/Rubik/Rubik-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RubikItalic";
  src: url("./Fonts/Rubik/Rubik-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "RubikLight";
  src: url("./Fonts/Rubik/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "RubikMedium";
  src: url("./Fonts/Rubik/Rubik-Medium.ttf") format("truetype");
}

/* ======================================================================================================= */
/* Poppins Font  */
@font-face {
  font-family: "Poppins";
  src: url("./Fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  src: url("./Fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: url("./Fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsItalic";
  src: url("./Fonts/Poppins/Poppins-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsExtraBold";
  src: url("./Fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
}

/* ======================================================================================================= */
/* Be Vietnam Font  */
@font-face {
  font-family: "BeVietnam";
  src: url("./Fonts/Be_Vietnam_Pro/BeVietnamPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnamBold";
  src: url("./Fonts/Be_Vietnam_Pro/BeVietnamPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnamSemiBold";
  src: url("./Fonts/Be_Vietnam_Pro/BeVietnamPro-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnamExtraBold";
  src: url("./Fonts/Be_Vietnam_Pro/BeVietnamPro-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnamLight";
  src: url("./Fonts/Be_Vietnam_Pro/BeVietnamPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnamExtraLight";
  src: url("./Fonts/Be_Vietnam_Pro/BeVietnamPro-ExtraLight.ttf") format("truetype");
}