.custom-shape-divider-bottom-1716876360 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1716876360 svg {
    position: relative;
    display: block;
    width: calc(155% + 1.3px);
    height: 160px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1716876360 .shape-fill {
    fill: #ffffff;
}